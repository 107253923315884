<template>
  <div class="business">
    <div class="business_head">
      <div class="business_head_title">药材合集 > {{ tabList[tabIndex] }}</div>
      <div style="color: transparent;" v-show="changePage">{{ changePage }}</div>
    </div>
    <div class="business_list">
      <div class="business_list_left">
        <div class="business_list_left_head">道地药材</div>
        <div class="business_list_left_tabList">
          <div class="business_list_left_tabList_tab" :class="index == tabIndex ? 'isActive' : ''"
            @click="setTabIndex(index)" v-for="(item, index) in tabList" :key="item">
            {{ item }}
          </div>
        </div>
      </div>
      <div class="business_list_right">
        <div class="business_list_right_item" v-for="item in medicineList" :key="item.name" @click="toDetails(item)">
          <div class="business_list_right_item_img">
            <img :src="require('../../static/image/' + item.medicineName + '.jpg')" width="100%" />
          </div>
          <div class="business_list_right_item_name">{{ item.medicineName }}</div>
          <div class="business_list_right_item_alias">
            别名：{{ item.medicineOtherName }}
          </div>
          <div class="business_list_right_item_area">产地：{{ item.medicineOriginPlace }}</div>
        </div>
      </div>
    </div>
    <div class="business_pagination">
      <el-pagination layout="prev, pager, next" :page-size="18" :current-page="query.page" :total="query.total"
        @current-change="handleCurrentChange"></el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getAccessToken, getSignature } from '@/api/index'
export default {
  name: "business",
  data() {
    return {
      tabList: [
        "关药",
        "北药",
        "怀药",
        "维药",
        "浙药",
        "海药",
        "南药",
        "蒙药",
        "川药",
        "云药",
        "贵药",
        "广药",
        "淮药",
        "藏药",
        "秦药",
      ],
      medicineList: [],
      tabIndex: 0,
      query: {
        page: 1,
        size: 18,
        total: 175,
      },
      changePage: 0,
      name: ''
    };
  },
  mounted() {
    if (this.$route.query.tabIndex) {
      this.tabIndex = Number(this.$route.query.tabIndex);
    }
    this.getList()
    window.scroll(0, 0);
    this.getWXInfo()
  },
  methods: {
    getWXInfo() {
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp
        const nonceStr = res.data.noncestr
        const signature = res.data.signature
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxad8dd02ae97d307f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: '中峪道地药材', // 分享标题
            desc: '药材合集', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://www.zoneyu.cc/img/%E8%BF%9C%E5%BF%97.3fda36cd.jpg', // 分享图标
          }
          wx.updateAppMessageShareData(obj)
          wx.updateTimelineShareData(obj)
        });
      })
    },
    getList() {
      axios.get(
        `https://admin.zoneyu.cn/cms/api/app/medicine/list?pageNum=${this.query.page}&pageSize=18&medicineType=${this.tabList[this.tabIndex]}&medicineName=`
      ).then(async (res) => {
        this.medicineList = res.data.data.list
        this.query.total = res.data.data.total
      });
    },
    setTabIndex(index) {
      this.changePage += 1
      this.$set(this.query, 'page', 1)
      this.tabIndex = index;
      this.getList()
      window.scroll(0, 0);
    },
    toDetails(item) {
      this.$router.push({
        path: "/businessDetail",
        query: { id: item.id, tabIndex:this.tabIndex },
      });
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getList()
    },
  },
};
</script>

<style scoped lang="scss">
.business {
  border-top: 10px solid #18511c;
}

.business_head {
  width: 1280px;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 400;
  color: #858585;
  line-height: 20px;

  .business_head_title {
    margin-top: 40px;
  }
}

.business_list {
  display: flex;
  width: 1280px;
  margin: 0 auto;
  margin-top: 30px;

  .business_list_left {
    width: 240px;
    margin-right: 40px;

    .business_list_left_head {
      width: 240px;
      height: 64px;
      background: #18511c;
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      line-height: 64px;
      letter-spacing: 15px;
      text-align: center;
    }

    .business_list_left_tabList_tab {
      width: 240px;
      height: 64px;
      border-bottom: 1px solid #f5f5f7;
      border-left: 1px solid #f5f5f7;
      border-right: 1px solid #f5f5f7;
      line-height: 64px;
      text-align: center;
      cursor: pointer;
      position: relative;
    }

    .isActive {
      background: #F9F9F7;
    }

    .business_list_left_tabList_tab:hover {
      background: #F9F9F7;
    }

    .isActive::after {
      content: "";
      position: absolute;
      top: 25px;
      left: 170px;
      width: 0;
      height: 0;
      border: 7px solid transparent;
      /*以下四个样式对应四种三角形，任选其一即可实现*/
      border-left-color: #18511c;
    }
  }

  .business_list_right {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    cursor: pointer;

    .business_list_right_item {
      margin: 0 15px 15px 0;
      width: 300px;
      padding: 10px;
      border: 1px solid #00000011;
      border-radius: 5px;

      .business_list_right_item_name {
        font-size: 18px;
        font-weight: 400;
        color: #252525;
        line-height: 25px;
        margin: 16px 0 10px 0;
      }

      .business_list_right_item_alias,
      .business_list_right_item_area {
        font-size: 14px;
        font-weight: 400;
        color: #858585;
        line-height: 23px;
        letter-spacing: 1px;
        overflow: hidden; // 溢出隐藏
        white-space: nowrap; // 强制一行
        text-overflow: ellipsis; // 文字溢出显示省略号
      }
    }

    .business_list_right_item:nth-child(3n) {
      margin-right: 0; // 去除第3n个的margin-right
    }
  }
}

.business_pagination {
  margin: 0 auto;
  margin-top: 16px;
  text-align: center;
  margin-bottom: 64px;
  padding-left: 280px;
  width: 1000px;
}

::v-deep .el-pager li.active {
  width: 28px;
  height: 28px;
  background: #354a32;
  border-radius: 2px;
  color: #ffffff;
  line-height: 28px;
}

::v-deep .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  color: #565759;
  line-height: 28px;
  padding: 0;
  margin: 0 4px;
  font-weight: 400;
}

::v-deep .el-pager li.active+li {
  border: 1px solid #e5e5e5;
}
</style>
